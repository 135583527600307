const DocumentationRoutes = [
  {
    title: 'documentation',
    content: [
      {
        title: 'introduction',
        children: [
          'Getting Started',
          'Concepts',
          'Booking API',
          'Access Control API',
          'FAQ'
        ]
      },
      {
        title: 'access-control-overview',
        children: [
          'Introduction',
          'Access Control flow'
        ]
      },
      {
        title: 'pagination',
        children: [
          'Introduction',
          'Paginated Response'
        ]
      },
      {
        title: 'response-errors',
        children: [
          'Introduction',
          'Status code 403 (Forbidden)',
          'Status code 404 (Not Found)',
          'Status code 400 (Bad Request)',
          'Status code 409 (Conflict)',
          'Status Code 500 (Internal Server Error)'
        ]
      },
      {
        title: 'studio-onboarding',
        children: [
          'Introduction',
          'Studio Onboarding',
          'FAQ'
        ]
      },
      {
        title: 'webhooks-overview',
        children: [
          'Introduction',
          'Booking Notification',
          'Booking Lifecycle',
          'Event Ready to Book'
        ]
      }
    ]
  },
  {
    title: 'endpoint',
    content: [
      {
        title: 'access-control',
        children: [
          'Introduction',
          'Check-in Validation'
        ]
      },
      {
        title: 'authentication',
        children: [
          'Introduction',
          'Request a Token'
        ]
      },
      {
        title: 'bookings',
        children: [
          'Introduction',
          'Get Bookings',
          'Update Booking Count',
          'Update Booking'
        ]
      },
      {
        title: 'event-categories',
        children: [
          'Introduction',
          'Get Categories'
        ]
      },
      {
        title: 'events',
        children: [
          'Introduction',
          'Create Events',
          'Create Events with Idempotency Support',
          'Edit an Event',
          'Cancel an Event',
          'Get All Events',
          'Get Single Event',
          'FAQ'
        ]
      },
      {
        title: 'locations',
        children: [
          'Introduction',
          'Get Locations'
        ]
      },
      {
        title: 'memberships',
        children: [
          'Introduction',
          'Get Customer ID'
        ]
      },
      {
        title: 'trainers',
        children: [
          'Introduction',
          'Create Trainer',
          'Get Trainers',
          'FAQ'
        ]
      },
      {
        title: 'webhooks',
        children: [
          'Introduction',
          'Create Webhook Endpoints',
          'Get Webhook Endpoints',
          'Update Webhook Endpoint',
          'Delete Webhook Endpoint'
        ]
      }
    ]
  }
];
export default DocumentationRoutes;
